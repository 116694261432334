<template>
  <div class="change-password">
    <div class="title">修改密码</div>
    <div class="content">
      <a-form :layout="formState.layout" :model="formState" v-bind="formItemLayout" @finish="handleUserChangePassword">
        <a-form-item label="当前密码">
          <a-input v-model:value="formState.oldPassword" type="password" placeholder="请输入当前密码" />
        </a-form-item>
        <a-form-item label="新密码">
          <a-input v-model:value="formState.newPassword" type="password" placeholder="请输入新密码" />
        </a-form-item>
        <a-form-item label="确认密码">
          <a-input v-model:value="formState.confirmPassword" type="password" placeholder="请确认密码" />
        </a-form-item>
        <a-form-item :wrapper-col="buttonItemLayout.wrapperCol">
          <a-button
            type="primary"
            html-type="submit"
            :disabled="formState.oldPassword === '' || formState.newPassword === '' || formState.confirmPassword === ''"
          >
            登录
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, reactive } from 'vue';
import { message, Modal } from 'ant-design-vue';
import Cookies from 'js-cookie';
import api from '@/services';

export default defineComponent({
  setup() {
    const formState = reactive({
      layout: 'horizontal',
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    });
    const formItemLayout = computed(() => {
      const { layout } = formState;
      return layout === 'horizontal'
        ? {
            labelCol: {
              span: 4
            },
            wrapperCol: {
              span: 14
            }
          }
        : {};
    });

    const buttonItemLayout = computed(() => {
      const { layout } = formState;
      return layout === 'horizontal'
        ? {
            wrapperCol: {
              span: 14,
              offset: 4
            }
          }
        : {};
    });

    const handleUserChangePassword = async params => {
      if (formState.newPassword !== formState.confirmPassword) {
        message.error('确认密码与新密码不一致！');
        return;
      }

      params = {
        newPassword: formState.newPassword,
        oldPassword: formState.oldPassword
      };

      let { success } = await api.userChangePassword(params);
      if (success) {
        Modal.success({
          title: '提示',
          content: '成功修改密码，请重新登录!',
          onOk() {
            Cookies.remove('SS_token');
            localStorage.clear();
            location.href = '/login';
          }
        });
      }
    };
    return {
      formState,
      formItemLayout,
      buttonItemLayout,
      handleUserChangePassword
    };
  }
});
</script>

<style lang="less" scoped>
.change-password {
  .title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #007fff;
    line-height: 28px;
  }

  .content {
    margin-top: 38px;
  }
}
</style>
